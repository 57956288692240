import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Native HMTL-Elemente sind von Haus aus barrierefrei. Die meisten lassen sich auch beliebig mit CSS stylen – wie ich in
meinem `}<a parentName="p" {...{
        "href": "https://www.oidaisdes.org/de/styling-accessible-web-forms.de"
      }}>{`Artikel über Web-Formulare`}</a>{` gezeigt habe. Das
Element zur Datei-Auswahl zählt leider nicht dazu. `}</p>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` Element wird vom Browser als Schaltfläche dargestellt, welche
den Datei-Auswahldialog des Betriebssystems öffnet. Diese Schaltfläche lässt sich gar nicht mit CSS stylen – weder die
Größe und Farbe noch die Schriftart. Aber, kein Grund zu verzweifeln! Ich zeige euch wie es geht.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGmVDVz0f/EABsQAAEEAwAAAAAAAAAAAAAAAAEAAgMREzFD/9oACAEBAAEFAhIXNzWBqc0uIfY//8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BS//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAfEAACAQIHAAAAAAAAAAAAAAAAAQMCEBEhMkFRkcH/2gAIAQEABj8Cbcbw2M4/DV3ZVciP/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8hvCBu7gqrPGKaI+DMctcg5lKc3FVVY4z/2gAMAwEAAgADAAAAECDf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIXH/2gAIAQMBAT8QSmmaP//EABcRAAMBAAAAAAAAAAAAAAAAAAABQXH/2gAIAQIBAT8Qbpk//8QAHRABAQABBAMAAAAAAAAAAAAAAREAITFBYVGB8P/aAAgBAQABPxDZiYQrlQ1nebLkgFPQ+cLCIlhjpzl2LcuBRAU+0yyTRTQ9XP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Person sucht in einer Aktentasche nach einem Dokument.",
          "title": "Eine Person sucht in einer Aktentasche nach einem Dokument.",
          "src": "/static/832405f6c8080e07c2a951f8c45cdabb/e5166/pexels-anete-lusina-pick-file.jpg",
          "srcSet": ["/static/832405f6c8080e07c2a951f8c45cdabb/f93b5/pexels-anete-lusina-pick-file.jpg 300w", "/static/832405f6c8080e07c2a951f8c45cdabb/b4294/pexels-anete-lusina-pick-file.jpg 600w", "/static/832405f6c8080e07c2a951f8c45cdabb/e5166/pexels-anete-lusina-pick-file.jpg 1200w", "/static/832405f6c8080e07c2a951f8c45cdabb/b17f8/pexels-anete-lusina-pick-file.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Anete Lusina / pexels.com`}</em></p>
    <h2>{`Schritt 1: Native HTML-Elemente verwenden`}</h2>
    <p>{`Wenn ich ein Web-Formular mit Datei-Auswahl baue, dann sollen alle Nutzer:innen Dateien auswählen und hochladen können.
Dazu zählen auch Menschen, die auf Screenreader oder die Tastaturbedienung angewiesen sind. Aus diesem Grund verwende
ich das native, barrierefreie `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` Element. Ich habe eine Demo
mit dem React-Framework erstellt. So sieht mein `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX-Code`}</a>{` aus:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<label htmlFor="filepicker" className={styles.filePicker}>
    <span>Upload PDF</span>
    <input
        id="filepicker"
        type="file"
        accept=".pdf"
        aria-describedby="selected-file"
        onChange={event => onFilePickerChange(event)}
    />
</label>
<p id="selected-file">{selectedFile}</p>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ich verwende ein `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` Element, das die sichtbare Beschriftung meiner Datei-Auswahl
("Upload PDF") sowie das visuell verborgene `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` Element enthält. Auf diese Weise fungiert
das `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` Element als sichtbare UI-Komponente, das sich beliebig stylen lässt (mehr dazu in
Schritt 2).`}</p>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`p`}</InlineCode>{` Element zeigt den Hinweis an, dass noch keine Datei ausgewählt wurde. Nach der Auswahl einer
Datei zeigt es den Dateinamen (siehe Schritt 3). Dank des Attributs `}<InlineCode mdxType="InlineCode">{`aria-describedby`}</InlineCode>{` wird der
Text auch von Screenreadern vorgelesen, wenn die Nutzer:innen mit der Datei-Auswahl interagieren.`}</p>
    <p>{`Ich habe auch versucht, mithilfe von `}<InlineCode mdxType="InlineCode">{`aria-hidden="true"`}</InlineCode>{` den Absatz selbst vor assistiven
Technologien zu verbergen. Da dies bei bestimmten Browser- und Screenreader-Kombinationen zu Problemen führte, habe
ich das Attribut wieder entfernt. Jetzt hören Screenreader-Nutzer:innen den Hinweis möglicherweise zweimal. Das ist
immer noch besser, als ihn gar nicht zu hören.`}</p>
    <h2>{`Schritt 2: Etwas CSS-Magie anwenden`}</h2>
    <p>{`Als nächstes mache ich das `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` Element mit CSS unsichtbar und positioniere es über
dem `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` Element. Das erlaubt mir, die sichtbare Beschriftung benutzerdefiniert zu gestalten:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`form label[for].filePicker {
    position: relative;
    background-color: rgb(49, 4, 92);
    color: white;
    font-size: 1rem;
    // ... more custom styling
}

form label[for].filePicker input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Achtet bitte darauf, das `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` Element nicht vor assistiven Technologien
zu verbergen. Die Nutzung von `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{` oder das Setzen einer Größe von null Pixel
würde dazu führen, dass Screenreader das Element nicht mehr auffinden können.`}</p>
    <p>{`Damit auch sehende Nutzer:innen ein visuelles Feedback erhalten, zeichne ich eine Outline für
das `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` Element ein, wenn der Mauszeiger darüber bewegt wird oder wenn das darin
verschachtelte `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` Element fokussiert wird.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`form label[for].filePicker:focus-within,
form label[for].filePicker:hover {
    outline: 2px solid black;
    outline-offset: 2px;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Schritt 3: Eine Prise JavaScript`}</h2>
    <p>{`Als letzten Schritt möchte ich den Dateinamen anzeigen, wenn Nutzer:innen eine Datei ausgewählt haben. Zu diesem Zweck
nutz ich das `}<InlineCode mdxType="InlineCode">{`change`}</InlineCode>{` Event, das ausgelöst wird, wenn der Eingabewert verändert wird.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`const FileUpload: React.FunctionComponent = () => {
    const [selectedFile, setSelectedFile] = useState('No file selected');

    const onFilePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files ?? []);
        if (files.length > 0) {
            setSelectedFile(files[0].name);
        }
    }

    return (
        // Only the relevant sections of the JSX code
        <input type="file"
            // ...
            onChange={event => onFilePickerChange(event)}
        />
        <p id="selected-file">{selectedFile}</p>
        // ...
    );
};`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Natürlich könnt ihr das auch in reinem JavaScript umsetzen – oder irgendeinem Framework eurer Wahl. Ich liefere euch
nur ein Beispiel für eine Implementierung als React-Komponente. Seht euch den kompletten
`}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/fancy-css-playground/tree/main/src/pages/FileUpload"
      }}>{`Quellcode auf GitHub`}</a>{` an.`}</p>
    <h2>{`Die perfekte Datei-Auswahl, oder?`}</h2>
    <p>{`Hier seht ihr meine gestylte Datei-Auswahl in Aktion. Probiert es aus! Zum Vergleich habe ich auch ein
unverändertes `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` Element eingebaut.`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/fileupload" title="Demo Gestylte Datei-Auswahl"></iframe>
    <p>{`Ich bin mit dem Resultat an sich sehr glücklich. Allerdings geht es immer besser. Die Datei-Auswahl lässt sich super
mit der Tastatur bedienen. Auch Screenreader-Nutzer:innen können mit dem Element gut interagieren, wobei es in Kombination
mit gewissen Browsern und Plattformen kleinere Probleme gibt (`}<a parentName="p" {...{
        "href": "/accessible-file-input.en"
      }}>{`siehe englische Version des Artikels`}</a>{`).`}</p>
    <p>{`Vielleicht werden diese Probleme durch zukünftige Updates gelöst. Oder ich finde noch eine bessere Lösung.
Jedenfalls zeigt meine Demo, dass eine barrierefreie, gestylte Datei-Auswahl für Web-Formulare keine Hexerei ist.`}</p>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update am 26.03.2023</PostUpdateTitle>
    <p>{`Ich habe mich geirrt: Es gibt tatsächlich eine Möglichkeit, das native `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{`
HTML-Element anzupassen. Ihr könnt das `}<InlineCode mdxType="InlineCode">{`::file-selector-button`}</InlineCode>{` CSS-Pseudoelement verwenden, um die
gerenderte Schaltfläche zu gestalten. Leider könnt ihr den Text, der innerhalb der Schaltfläche gerendert wird, nicht ändern.
Hier findet ihr eine `}<a parentName="p" {...{
        "href": "https://css-tricks.com/almanac/selectors/f/file-selector-button/"
      }}>{`Demo mit weiteren Informationen`}</a>{` dazu.`}</p>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update am 10.12.2023</PostUpdateTitle>
    <p>{`Ich habe den HTML-Code in meinem Beispiel überarbeitet und die Screenreader-Tests mit den aktuellen Browserversionen erneut
durchgeführt.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      